import React, { useState, useEffect, useRef } from "react";
import Main from "../components/layout/Main";
import lang from "../helper/lang";
function Home() {



  return (
    <Main>
      <div className="layout-content">
        <div className="dashboard-card">
         {lang("In Progress")}
        </div>

      </div>
    </Main>
  );
}

export default Home;
