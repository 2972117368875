import React from "react";
import { Container } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Cart = () => {
  return (
    <Container className="main-outer-s">
      <div className="row mt-5">
        <div className="col-1"></div>
        <div className="col-2">
          <Skeleton count={1} width={"90%"} height={50} />
        </div>
        <div className="col-2">
          <Skeleton count={1} width={"90%"} height={50} />
        </div>
        <div className="col-2">
          <Skeleton count={1} width={"90%"} height={50} />
        </div>
        <div className="col-2">
          <Skeleton count={1} width={"90%"} height={50} />
        </div>
        <div className="col-2">
          <Skeleton count={1} width={"90%"} height={50} />
        </div>

        <div className="col-1"></div>
      </div>
      <div className="row mt-3">
        <div className="col-1"></div>
        <div className="col-2">
          <Skeleton count={1} width={"100%"} height={150} />
        </div>
        <div className="col-2 d-flex align-items-center justify-content-center">
          <Skeleton count={2} width={175} height={35} />
        </div>
        <div className="col-2 d-flex align-items-center justify-content-center">
          <Skeleton count={1} width={100} height={30} />
        </div>
        <div className="col-2 d-flex align-items-center justify-content-center">
          <Skeleton count={1} width={100} height={30} />
        </div>
        <div className="col-2 d-flex align-items-center justify-content-center">
          <Skeleton count={1} width={175} height={80} />
        </div>

        <div className="col-1"></div>
      </div>
      <div className="row mt-3">
        <div className="col-1"></div>
        <div className="col-2">
          <Skeleton count={1} width={"100%"} height={150} />
        </div>
        <div className="col-2 d-flex align-items-center justify-content-center">
          <Skeleton count={2} width={175} height={35} />
        </div>
        <div className="col-2 d-flex align-items-center justify-content-center">
          <Skeleton count={1} width={100} height={30} />
        </div>
        <div className="col-2 d-flex align-items-center justify-content-center">
          <Skeleton count={1} width={100} height={30} />
        </div>
        <div className="col-2 d-flex align-items-center justify-content-center">
          <Skeleton count={1} width={175} height={80} />
        </div>

        <div className="col-1"></div>
      </div>
      <div className="row mt-3">
        <div className="col-1"></div>
        <div className="col-2">
          <Skeleton count={1} width={"100%"} height={150} />
        </div>
        <div className="col-2 d-flex align-items-center justify-content-center">
          <Skeleton count={2} width={175} height={35} />
        </div>
        <div className="col-2 d-flex align-items-center justify-content-center">
          <Skeleton count={1} width={100} height={30} />
        </div>
        <div className="col-2 d-flex align-items-center justify-content-center">
          <Skeleton count={1} width={100} height={30} />
        </div>
        <div className="col-2 d-flex align-items-center justify-content-center">
          <Skeleton count={1} width={175} height={80} />
        </div>

        <div className="col-1"></div>
      </div>
    </Container>
  );
};

export default Cart;
