import React from "react";
import { Layout, Button, Typography, Form, Row,Col } from "antd";

import { Container } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Product = () => {
  return (
    <div className="main-outer-s">
      <Container>
        <div className="row">
        <Col span={24} lg={12}>
            <Skeleton count={1} width={"100%"} height={700} />
            <div className="mt-4"></div>
            <Skeleton count={1} width={"100%"} height={200} />
          </Col>
          <Col span={24} lg={12}>
            <Skeleton count={2} width={"100%"} height={40} />
            <div className="my-2"></div>
            <Skeleton count={2} width={"100%"} height={20} />
            <div className="my-2"></div>
            <Skeleton count={1} width={"20%"} height={50} />
            <div className="my-4"></div>
            <Skeleton count={3} width={"90%"} height={100} />
            <div className="my-4"></div>
            <Skeleton count={4} width={"60%"} height={30} />
          </Col>
        </div>
      </Container>
    </div>
  );
};

export default Product;
