import { Button, Col, Form, Input, Modal, Row, Switch, Typography } from "antd";
import React, { useContext, useState } from "react";
import RegisterModal from "./RegisterModal";
import Logo from "../../assets/images/CASA PHONE 1.png";
import useRequest from "../../hooks/useRequest";
import { AuthContext } from "../../context/AuthContext";
const { Title } = Typography;

const LoginModal = ({ open, setOpen }) => {
  const [form] = Form.useForm();
  const [register, setRegister] = useState(false);
  const { request } = useRequest();
  const { setUserProfile } = useContext(AuthContext);
  const handleSubmit = (values) => {
    request({
      url: "/app/auth/login",
      method: "POST",
      data: values,
      onSuccess: (data) => {
        setOpen(false);
        const { user, token } = data.data;
        setUserProfile(user);
        localStorage.setItem("userProfile", JSON.stringify(user));
        localStorage.setItem("token", token);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };
  return (
    <div>
      <Modal
        className="sign-modal maain-all-div"
        footer={null}
        open={open}
        onCancel={() => setOpen(false)}
      >
        <Row justify="space-around">
          <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
            <div className="signup-form">
              <div className="modal-tital">
                <div className="casa-logo-img">
                  <img src={Logo} />
                </div>
                <Title className="mb-15 login-maain-3">
                  <h2>Login Into</h2>
                  <h3>Your Account</h3>
                </Title>
                <Title className="font-regular text-muted" level={5}>
                  Enter your email and password to sign in
                </Title>
              </div>
              <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address!",
                    },
                    {
                      max: 255,
                      message: "Email address not more then 255 characters!",
                    },
                    {
                      required: true,
                      message: "Please enter your email!",
                    },
                  ]}
                >
                  <Input
                    className="sign-innn"
                    placeholder="Enter Email Address"
                  />
                </Form.Item>

                <Form.Item
                  className="password"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your password!",
                    },
                  ]}
                >
                  <Input.Password
                    className="sign-innn"
                    autoComplete="off"
                    placeholder="Enter Password"
                  />
                </Form.Item>
                <div className="forgot-pass">
                  <Form.Item
                    name="remember"
                    className="aligin-center main-remamber "
                    valuePropName="checked"
                  >
                    <Switch className="" />
                    Remember me
                  </Form.Item>
                  <Form.Item className="sign-innbtn">
                    <Button
                      className="sign-btn"
                      type="primary"
                      htmlType="submit"
                    >
                      SIGN IN
                    </Button>
                  </Form.Item>
                </div>

                <Form.Item
                  name="remember"
                  className="aligin-center dont-acc"
                  valuePropName="checked"
                >
                  <a
                    onClick={() => {
                      setRegister(true);
                      setOpen(false);
                    }}
                    className="forgate-btn"
                  >
                    Don't have account ? <span> Sign up</span>
                  </a>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </Modal>
      <RegisterModal open={register} setOpen={setRegister} />
    </div>
  );
};

export default LoginModal;
