import { Button } from "antd";

import React, { useContext, useEffect, useState } from "react";

import { Tabs } from "antd";

import ArrowleftImg from "../assets/images/Arrowleft.png";
import { Container } from "react-bootstrap";
import PhoneImg from "../../src/assets/images/popurlarbrand.png";
import { useNavigate, useParams } from "react-router";
import useRequest from "../hooks/useRequest";
import { Severty, ShowToast } from "../helper/toast";
import { Link, useSearchParams } from "react-router-dom";
import withLoadingSkeleton from "../helper/Skeleton/withLoadingSkeleton";
import Footer from "../components/layout/Footer";
import { AuthContext } from "../context/AuthContext";
import BuyModal from "../components/layout/BuyModel";
import lang from "../helper/lang";

const SearchResult = ({ setLoading, setType, setPage, ...props }) => {
  setPage("category");
  const { request } = useRequest();
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search");
  const navigator = useNavigate();
  const [data, setData] = useState({});
  const [searchedData, setSearchedData] = useState([]);
  const [category, setCategory] = useState([]);
  const { userProfile } = useContext(AuthContext);
  const [buyModel, setBuyModel] = useState(false);
  const [productId, setProductId] = useState("");

  const handleSearch = () => {
    if (search) {
      console.log(search);
      request({
        url: `/common/search/${search}`,
        method: "GET",
        onSuccess: (data) => {
          setSearchedData(data.data.data);
          setLoading(false);
        },
        onError: (error) => {
          console.log(error);
          ShowToast(error, Severty.ERROR);
          setLoading(false);
        },
      });
    }
  };

  useEffect(() => {
    if (search) {
      handleSearch();
    }
  }, [search]);
  return (
    <div className="main-outer-s">
      <section>
        <Container>
          {/* this section for category ---start*/}

          <div className="main-hedaing-page">
            <Button onClick={() => navigator(-1)}>
              <img src={ArrowleftImg} /> {lang("Return")}
            </Button>
            {/* <h2>{lang("All Categories")}</h2> */}
          </div>

          <div className="main-tabs-content category-all mb-5">
            {searchedData &&
              searchedData.map((item) => (
                <Link to={`/product-detail/${item._id}`} key={item.id}>
                  <div className="birthday-new catere">
                    <div className="wedding-main">
                      <div className="poporlasr-red-div">
                        <h4>{lang("search")}</h4>
                      </div>
                    </div>

                    <div className="phone-immgand-txxt">
                      <div className="tabbs_immg">
                        <img src={item.image || PhoneImg} alt="" />
                      </div>

                      <div className="tabbs-txxt">
                        <h5>{item.productName}</h5>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export default withLoadingSkeleton(SearchResult);
