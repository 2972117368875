import React, { createContext, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { ShowToast, Severty } from "../helper/toast";
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [session, setSession] = useState("");
  const [userProfile, setUserProfile] = useState("");
  const [signUpModal, setSignUpModal] = useState(false);
  const [favoriteCount, setFavoriteCount] = useState(0);
  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userProfile = localStorage.getItem("userProfile");
    if (token) {
      setSession(token);
    }
    if (userProfile) {
      setUserProfile(JSON.parse(userProfile));
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      localStorage.setItem("token", session);
    }
  }, [session]);

  useEffect(() => {
    const userProfile = localStorage.getItem("userProfile");
    if (userProfile && Object.keys(userProfile).length <= 0) {
      localStorage.setItem("userProfile", JSON.stringify(userProfile));
    }
  }, [userProfile]);

  useEffect(() => {
    if (!isLoggedIn) return;
  }, [isLoggedIn]);

  const login = () => {
    setIsLoggedIn(true);
    // alert("login");
    return <Navigate to="/" />;
  };

  const logout = () => {
    //localStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("userProfile");
    setIsLoggedIn(false);
    setSession("");
    setUserProfile(null);
    ShowToast("Logout Successfully", Severty.SUCCESS);
    //window.location.assign("/login");
    return <Navigate to="/" />;
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        session,
        setSession,
        userProfile,
        setUserProfile,
        login,
        logout,
        signUpModal,
        setSignUpModal,
        favoriteCount,
        setFavoriteCount,
        cartCount,
        setCartCount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
