import { Button, Col, Form, Input, Modal, Row, Switch, Typography } from "antd";
import React, { useContext, useState } from "react";
import Logo from "../../assets/images/CASA PHONE 1.png";
import useRequest from "../../hooks/useRequest";
import { Severty, ShowToast } from "../../helper/toast";
const { Title } = Typography;

const RegisterModal = ({ open, setOpen }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const handleSubmit = (values) => {
    request({
      url: "/app/auth/sign-up",
      method: "POST",
      data: values,
      onSuccess: (data) => {
        setOpen(false);
        ShowToast(data.message, Severty.SUCCESS);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };
  return (
    <div>
      <Modal
        className="sign-modal main-login-moodel"
        footer={null}
        //   onFinish={handleSubmit}
        open={open}
        onCancel={() => setOpen(false)}
      >
        <Row justify="space-around">
          <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
            <div className="signup-form ">
              <div className="casa-logo-img">
                <img src={Logo} />
              </div>
              <div className="modal-tital">
                <Title className="mb-15"> </Title>
                <Title
                  className="font-regular text-muted signup-maain"
                  level={5}
                >
                  Sign Up
                </Title>
              </div>
              <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Enter Name"
                  name="name"
                  rules={[
                    {
                      max: 50,
                      message: "Email enter not more then 50 characters!",
                    },
                    {
                      required: true,
                      message: "Please enter your name!",
                    },
                  ]}
                >
                  <Input
                    className="sign-innn"
                    placeholder="Please enter your name"
                  />
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Email Address"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address!",
                    },
                    {
                      max: 255,
                      message: "Email address not more then 255 characters!",
                    },
                    {
                      required: true,
                      message: "Please enter your email!",
                    },
                  ]}
                >
                  <Input
                    className="sign-innn"
                    placeholder="Enter Email Address"
                  />
                </Form.Item>

                <Form.Item
                  className="password"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your password!",
                    },
                  ]}
                >
                  <Input.Password
                    className="sign-innn"
                    autoComplete="off"
                    placeholder="Enter Password"
                  />
                </Form.Item>

                <Form.Item
                  className="password"
                  label="Confirm Password"
                  name="confirmPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the confirm password!",
                    },
                    // {
                    //   pattern: new RegExp(
                    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
                    //   ),
                    //   message:
                    //     "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
                    // },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Password that you entered doesn't match!")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    className="sign-innn"
                    autoComplete="off"
                    placeholder="Enter Confirm Password"
                  />
                </Form.Item>

                <div className="forgot-pass">
                  <Form.Item
                    name="remember"
                    className="aligin-center main-remamber"
                    valuePropName="checked"
                  >
                    <Switch className="" />
                    Remember me
                  </Form.Item>
                </div>

                <Form.Item className="sign-innbtn">
                  <Button className="sign-btn" type="primary" htmlType="submit">
                    SIGN IN
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default RegisterModal;
