import React from "react";
import { Container } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const Category = () => {
  return (
    <div>
      <Container className="main-outer-s">
        <div>
          <Skeleton count={1} width={100} height={50} />
        </div>
        
        <div className="d-flex justify-content-between mt-5">
          <Skeleton count={1} width={200} height={250} />
          <Skeleton count={1} width={200} height={250} />
          <Skeleton count={1} width={200} height={250} />
          <Skeleton count={1} width={200} height={250} />
          <Skeleton count={1} width={200} height={250} />
        </div>
        <div className="d-flex justify-content-between mt-5">
          <Skeleton count={1} width={200} height={250} />
          <Skeleton count={1} width={200} height={250} />
          <Skeleton count={1} width={200} height={250} />
          <Skeleton count={1} width={200} height={250} />
          <Skeleton count={1} width={200} height={250} />
        </div>
        <div className="d-flex justify-content-between mt-5">
          <Skeleton count={1} width={200} height={250} />
          <Skeleton count={1} width={200} height={250} />
          <Skeleton count={1} width={200} height={250} />
          <Skeleton count={1} width={200} height={250} />
          <Skeleton count={1} width={200} height={250} />
        </div>
      </Container>
    </div>
  );
};

export default Category;
