import { Button } from "antd";

import React, { useContext, useEffect, useState } from "react";

import { Tabs } from "antd";

import Header from "../components/layout/Header";
import ArrowleftImg from "../assets/images/Arrowleft.png";
import { Container } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel3";
import NoImage from "../../src/assets/images/img-not-found.png";
import { useNavigate, useParams } from "react-router";
import useRequest from "../hooks/useRequest";
import { Severty, ShowToast } from "../helper/toast";
import { Link } from "react-router-dom";
import withLoadingSkeleton from "../helper/Skeleton/withLoadingSkeleton";
import Footer from "../components/layout/Footer";
import { AuthContext } from "../context/AuthContext";
import BuyModal from "../components/layout/BuyModel";
import lang from "../helper/lang";

const { TabPane } = Tabs;

const onChange = (key) => {
  console.log(`Active tab key: ${key}`);
};
const CategoriesIndex = ({ setLoading, setType, setPage, ...props }) => {
  const responsive = {
    0: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 5,
    },
    1200: {
      items: 8,
    },
  };
  const [selectedProduct, setSelectedProduct] = useState(null);
  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  setPage("category");
  const { request } = useRequest();
  const { id } = useParams();
  const navigator = useNavigate();
  const [data, setData] = useState({});
  const [category, setCategory] = useState([]);
  const { userProfile } = useContext(AuthContext);
  const [buyModel, setBuyModel] = useState(false);
  const [productId, setProductId] = useState("");

  useEffect(() => {
    if (data?.product && data?.product.length > 0) {
      setSelectedProduct(data?.product[0]);
    }
  }, [data]);

  useEffect(() => {
    if (id !== "all") {
      setLoading(true);
      request({
        url: `/common/category/${id}`,
        method: "GET",
        onSuccess: (data) => {
          setData(data.data);
          setCategory([]);
          setLoading(false);
        },
        onError: (error) => {
          console.log(error);
          ShowToast(error, Severty.ERROR);
          setLoading(false);
        },
      });
    }
    if (id === "all") {
      setLoading(true);
      request({
        url: "/common/category-list",
        method: "GET",
        onSuccess: (data) => {
          // setCategories(data.data.data);
          console.log(data.data.data);
          setCategory(data.data.data);
          setData({});
          setLoading(false);
        },
        onError: (error) => {
          console.log(error);
          ShowToast(error, Severty.ERROR);
          setLoading(false);
        },
      });
    }
  }, [id]);
  const handleToCheckout = (value) => {
    if (userProfile && userProfile.loginType === "Guest") {
      setBuyModel(true);
      setProductId(value);
    } else {
      let payload = {
        productId: value,
      };
      setLoading(true);
      setType("loader");
      console.log("payload", payload);
      request({
        url: "/app/user/buy-product",
        method: "POST",
        data: payload,
        onSuccess: (data) => {
          ShowToast(data.message, Severty.SUCCESS);
          setLoading(false);
        },
        onError: (error) => {
          console.log(error);
          setLoading(false);
        },
      });
    }
  };
  const toggleModel = (value) => {
    setBuyModel(value);
    setProductId("");
  };
  return (
    <div className="main-outer-s">
      <section className="category-section">
        <BuyModal id={productId} open={buyModel} setOpen={toggleModel} />

        <Container>
          {data.category && (
            <div className="main-hedaing-page">
              <Button onClick={() => navigator(-1)}>
                <img src={ArrowleftImg} /> {lang("Return")}
              </Button>
              {/* <h2>{data.category.name}</h2> */}
              {/* <p>{data?.category?.description || ""}</p> */}
            </div>
          )}

          {/* this section for category ---start*/}
          {id === "all" && (
            <div className="main-hedaing-page">
              <Button onClick={() => navigator(-1)}>
                <img src={ArrowleftImg} /> {lang("Return")}
              </Button>
              {/* <h2>{lang("All Categories")}</h2> */}
            </div>
          )}

          <div className="body-newcategory-tab">
            <ul className="tab-listed-menu">
              {data.product &&
                data.product.map((product, index) => (
                  <li>
                    <Link to={`/product-detail/${product._id}`}>
                      <div className="main-categories-list">
                        <div className="birthday-new nav-btns">
                          <div className="wedding-main">
                            <div className="poporlasr-red-div">
                              <h6>{lang("Popular")}</h6>
                            </div>
                          </div>
                          <div className="phone-immgand-txxt">
                            <div className="products-img">
                              <img src={product?.image || NoImage} alt="" />
                            </div>
                            <div className="tabbs-text">
                              <h6>{product.productName}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </li>
                ))}
            </ul>
          </div>

          {/* this section for category ---start*/}
          {id === "all" && (
            <div className="main-tabs-content category-all mb-5">
              {category &&
                category.map((item) => (
                  <Link to={`/category/${item._id}`} key={item.id}>
                    <div className="birthday-new catere">
                      <div className="wedding-main">
                        <div className="poporlasr-red-div">
                          <h4>{lang("Categories")}</h4>
                        </div>
                      </div>

                      <div className="phone-immgand-txxt">
                        <div className="tabbs_immg">
                          <img src={item.image || NoImage} alt="" />
                        </div>

                        <div className="tabbs-txxt">
                          <h5>{item.name}</h5>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          )}
          {/* this section for category ---end*/}
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export default withLoadingSkeleton(CategoriesIndex);
