import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Typography,
  Space,
  Dropdown,
  Menu,
  Drawer,
} from "antd";

import { Link, useLocation, useParams } from "react-router-dom";
import OwlCarousel from "react-owl-carousel3";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  HeartFilled,
  DownOutlined,
  SearchOutlined,
  HeartOutlined,
  ShoppingCartOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import PhonrIcon from "../../assets/images/phone-call.png";
import WebIcon from "../../assets/images/Group.png";
import PhoneImg from "../../assets/images/popurlarbrand.png";
import Logo from "../../assets/images/logo.jpg";
import useRequest from "../../hooks/useRequest";
import { Severty, ShowToast } from "../../helper/toast";
import { AuthContext } from "../../context/AuthContext";
import UserIconImg from "../../assets/images/userLogo.png";
import carticonImg from "../../assets/images/carticon.png";
import LoginModal from "../../components/layout/LoginModal";
import withLoadingSkeleton from "../../helper/Skeleton/withLoadingSkeleton";
import lang from "../../helper/lang";
const { Title } = Typography;
const { Option } = Select;
const { SubMenu } = Menu;

const responsiveSetting = {
  0: {
    items: 1,
  },
  300: {
    items: 2,
  },

  1000: {
    items: 2,
  },
  1200: {
    items: 2,
  },
  1400: {
    items: 2,
  },
};

function Header({
  placement,
  setLoading,
  setPage,
  name,
  subName,
  onPress,
  handleSidenavColor,
  handleSidenavType,
  handleFixedNavbar,
}) {
  setPage("Header");
  const {
    favoriteCount,
    setFavoriteCount,
    cartCount,
    userProfile: userDetails,
    setUserProfile,
    setCartCount,
    logout,
  } = useContext(AuthContext);
  useEffect(() => window.scrollTo(0, 0));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [search, setSearch] = useState("");
  const [tokenErr, setTokenErr] = useState(false);
  const [selectedLng, setSelectedLng] = useState("en");
  const { request } = useRequest();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const searchRef = useRef(null);
  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };
  const { pathname } = useLocation();
  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setSearchedData([]);
    }
  };
  useEffect(() => {
    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
  };

  const handleChangeLng = (value) => {
    localStorage.setItem("languageSet", value);
    setSelectedLng(value);
    window.location.reload();
  };

  useEffect(() => {
    const lng = localStorage.getItem("languageSet");
    if (lng) setSelectedLng(lng);
    if (pathname) {
      setSearchedData([]);
      setSearch("");
    }
  }, [pathname]);

  const menu01 = (
    <Menu className="nav-sub-menu drop-11">
      {categories.map((item) => (
        <>
          <Menu.Item className="menu-bar2">
            <Link to={`/category/${item._id}`}> {item.name}</Link>
          </Menu.Item>
        </>
      ))}
    </Menu>
  );
  const menu02 = (
    <Menu className="nav-sub-menu drop-11">
      {categories.slice(4).map((item) => (
        <>
          <Menu.Item className="menu-bar2">
            <Link to={`/category/${item._id}`}> {item.name}</Link>
          </Menu.Item>
        </>
      ))}
    </Menu>
  );
  const token = localStorage.getItem("token");

  const handleLogout = () => {
    logout();
    request({
      url: "/app/auth/guest-login",
      method: "POST",
      onSuccess: (data) => {
        setUserProfile(data.data);
        localStorage.setItem("userProfile", JSON.stringify(data.data));
        localStorage.setItem("token", data.data.token);
        setLoading(false);
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const items = [
    // {
    //   label: <a href="">Edit Profile</a>,
    //   key: "0",
    // },

    {
      label: <Link to="/order-details">My Orders</Link>,
      key: "3",
    },
    {
      label: <span onClick={() => handleLogout()}>LogOut</span>,
      key: "1",
    },
  ];

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token || tokenErr) {
      setLoading(true);
      request({
        url: "/app/auth/guest-login",
        method: "POST",
        onSuccess: (data) => {
          setUserProfile(data.data);
          localStorage.setItem("userProfile", JSON.stringify(data.data));
          localStorage.setItem("token", data.data.token);
          setLoading(false);
        },
        onError: (error) => {
          console.log(error);
          ShowToast(error, Severty.ERROR);
          setLoading(false);
        },
      });
    }
  }, [tokenErr]);
  useEffect(() => {
    setLoading(true);
    if (token) {
      request({
        url: `/app/user/user-header-info`,
        method: "GET",
        onSuccess: (data) => {
          setFavoriteCount(data.data.favorite);
          setCartCount(data.data.cart);
          setLoading(false);
        },
        onError: (error) => {
          console.log(error);
          localStorage.removeItem("token");
          localStorage.removeItem("userProfile");
          setLoading(false);
          setTokenErr(true);
        },
      });
    }
    request({
      url: "/common/category-list",
      method: "GET",
      onSuccess: (data) => {
        setCategories(data.data.data);
        setLoading(false);
      },

      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
        setLoading(false);
      },
    });
  }, [token]);

  useEffect(() => {
    let timeOut;
    if (search) {
      timeOut = setTimeout(() => {
        handleSearch();
      }, 500);
    }
    return () => clearTimeout(timeOut);
  }, [search]);
  // console.log(userDetails);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleSearch = () => {
    if (search) {
      request({
        url: `/common/search/${search}`,
        method: "GET",
        onSuccess: (data) => {
          setSearchedData(data.data.data);
          setLoading(false);
        },
        onError: (error) => {
          console.log(error);
          ShowToast(error, Severty.ERROR);
          setLoading(false);
        },
      });
    }
  };
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <>
      <div sticky="top" className=" fixedHeader fixed_header">
        {/* <div className="background-main">
          <div className="container-fluid">
            <div className="main-header">
              <div className="phone-book">
                <img src={PhonrIcon} /> Support: India{" "}
                <a href="callto:18002663001" target="_blank">
                  1800 266 3001{" "}
                </a>
                , Global{" "}
                <a href="callto:18002800906" target="_blank">
                  +1800 280 0906{" "}
                </a>
              </div>
              <div className="world-book">
                <a href="mailto:casaphone.casa@gmail.com" target="_blank">
                  <img src={WebIcon} /> casaphone.co
                </a>
              </div>
            </div>
          </div>
        </div> */}

        <Navbar expand="lg" className="bg-body-tertiary">
          <Container fluid>
            <Navbar.Brand as={Link} to="/">
              <img src={Logo} alt="Logo" />
            </Navbar.Brand>
            <div className="search-maain order-3  order-md-0">
              <div className="search-container">
                {/* <Select
                defaultValue="all"
                className="category-dropdown"
                onChange={handleSelectChange}
                bordered={false}
              >
                <Option value="all">All</Option>
              </Select> */}
                <Input
                  type="text"
                  className="search-input"
                  placeholder={lang("Search Product...")}
                  onChange={handleSearchChange}
                  value={search}
                  bordered={false}
                />
                <button className="search-button">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <circle
                      cx="11"
                      cy="11"
                      r="7"
                      stroke="#FF4500"
                      stroke-width="2"
                    ></circle>
                    <line
                      x1="16.7071"
                      y1="16.2929"
                      x2="20.7071"
                      y2="20.2929"
                      stroke="#FF4500"
                      stroke-width="2"
                    ></line>
                  </svg>
                </button>
              </div>
              {searchedData.length > 0 && search && (
                <div className="search_dropdown_cls" ref={searchRef}>
                  <div className="row">
                    <div className="col-lg-4 col-12 order-lg-1 order-md-2">
                      <div>
                        <p className="title-search">Top Categories</p>
                        <div>
                          {categories.map((item, index) => (
                            <Link
                              className="link-search"
                              to={`/category/${item._id}`}
                              style={{ display: "block" }}
                              key={index}
                            >
                              {item.name}
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 col-12 order-lg-2 order-md-1">
                      <div className="d-flex justify-content-between mb-3 align-items-center">
                        <p className="title-search">
                          Products ({searchedData.length})
                        </p>
                        <Link to={`/search-result?search=${search}`}>
                          View all ({searchedData.length})
                        </Link>
                      </div>
                      <div className="serch-owl">
                        <OwlCarousel
                          responsive={responsiveSetting}
                          className="owl-serchbar"
                          loop
                          margin={10}
                          dots={false}
                        >
                          {searchedData.map((product, index) => (
                            <div className="item" key={index}>
                              <div className="arrival-prd">
                                <div className="arrival-prd-img search">
                                  <Link to={`/product-detail/${product._id}`}>
                                    <img
                                      src={product.image || PhoneImg}
                                      alt={product.productName}
                                    />
                                  </Link>
                                </div>
                                <div className="arrival-cont arrival-search-cont ">
                                  <div className="arrival-title">
                                    {/* <h3>{product.productName}</h3> */}
                                    <Link
                                      to={`/product-detail/${product._id}`}
                                      style={{ textDecoration: "none" }}
                                    >
                                      <p>{product.productName}</p>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </OwlCarousel>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="resposive-tpggle">
              <div className="fav-icon-logo d-lg-none">
                <Link to="/wishlist">
                  <HeartOutlined />
                  {favoriteCount > 0 && (
                    <span className="notifi-bttn">{favoriteCount}</span>
                  )}
                </Link>
              </div>

              <div className="cart-logo d-lg-none">
                <Link to="/cart">
                  <ShoppingCartOutlined />
                  {cartCount > 0 && (
                    <span className="notifi-bttn">{cartCount}</span>
                  )}
                </Link>
              </div>
              <Button
                type="primary"
                onClick={showDrawer}
                icon={<MenuOutlined />}
                className="d-lg-none toogle-btn"
              ></Button>
            </div>
            <Navbar className="d-none d-lg-block">
              <Nav className="ms-auto align-items-center">
                <Nav.Link className="nav-menu active" as={Link} to="/">
                  {lang("Home")}
                </Nav.Link>
                <Nav.Link className="nav-menu">
                  <Dropdown
                    overlay={menu01}
                    id="nack-dropdown"
                    placement="bottomRight"
                    overlayClassName="dropdown-right-to-left"
                    className="nav-menu"
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Space className="nav-dropmenu">
                        {lang("Categories")}
                        <DownOutlined className="dawn-head" />
                      </Space>
                    </a>
                  </Dropdown>
                </Nav.Link>
              </Nav>
            </Navbar>
          </Container>
        </Navbar>
      </div>

      <Drawer
        title="Menu"
        placement="right"
        onClose={closeDrawer}
        visible={drawerVisible}
        className="drawer-menu"
      >
        <Menu mode="vertical">
          <Nav.Link className="nav-menu active" as={Link} to="/">
            {lang("Home")}
          </Nav.Link>
          <Nav.Link className="nav-menu">
            <Dropdown
              overlay={menu01}
              id="nack-dropdown"
              placement="bottomRight"
              overlayClassName="dropdown-right-to-left"
              className="nav-menu"
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space className="nav-dropmenu">
                  {lang("Categories")}
                  <DownOutlined className="dawn-head" />
                </Space>
              </a>
            </Dropdown>
          </Nav.Link>
          <Nav.Link className="nav-menu">
            <Dropdown
              overlay={menu02}
              id="nack-dropdown"
              placement="bottomRight"
              overlayClassName="dropdown-right-to-left"
              className="nav-menu"
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space className="nav-dropmenu">
                  {lang("Accessories")}
                  <DownOutlined className="dawn-head" />
                </Space>
              </a>
            </Dropdown>
          </Nav.Link>
          {/* <Select
            className="lg-select"
            defaultValue="English"
            style={{ width: 120 }}
            onChange={handleChange}
            options={[
              { value: "en", label: "English" },
              { value: "sp", label: "Spanish" },
            ]}
          /> */}
          {/* Add more Menu.Items or SubMenus as needed */}
        </Menu>
      </Drawer>
      <LoginModal open={isModalOpen} setOpen={setIsModalOpen} />
    </>
  );
}

export default withLoadingSkeleton(Header);
