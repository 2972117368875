import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Switch,
  Typography,
  Select,
} from "antd";
import React, { useContext, useState } from "react";
import RegisterModal from "./RegisterModal";
import useRequest from "../../hooks/useRequest";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import TextArea from "antd/lib/input/TextArea";
import { Severty, ShowToast } from "../../helper/toast";
import lang from "../../helper/lang";
const { Title } = Typography;
const { Option } = Select;

const BuyModal = ({
  open,
  setOpen,
  id,
  getPayload = false,
  setData = () => {},
}) => {
  const [form] = Form.useForm();
  const [register, setRegister] = useState(false);
  const { request } = useRequest();
  const handleSubmit = (values) => {
    const { whatsapp } = values;
    let payload = {
      ...values,
      whatsapp: whatsapp ? whatsapp : false,
      productId: id,
    };
    if (!getPayload) {
      request({
        url: "/app/user/buy-product",
        method: "POST",
        data: payload,
        onSuccess: (data) => {
          ShowToast(data.message, Severty.SUCCESS);
          form.resetFields();
          setOpen(false);
        },
        onError: (error) => {
          console.log(error);
        },
      });
    } else {
      setData(values);
      form.resetFields();
    }
  };
  return (
    <div>
      <Modal
        className="sign-modal maain-all-div"
        footer={null}
        open={open}
        onCancel={() => {
          form.resetFields();
          setOpen(false);
        }}
      >
        <Row justify="space-around">
          <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
            <div className="signup-form main-buy-model">
              <div className="modal-tital">
                {/* <div className="casa-logo-img">
                  <img src={Logo} />
                </div> */}
                <Title className="mb-15 login-maain-3">
                  <h2>{lang("Buy Now")}</h2>
                </Title>
                <Title className="font-regular text-muted" level={5}>
                  {lang("Enter enter some details")}
                </Title>
              </div>
              <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="name"
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your name!",
                    },
                  ]}
                >
                  <Input
                    className="sign-innn"
                    autoComplete="off"
                    placeholder="Enter name"
                  />
                </Form.Item>
                <Form.Item
                  className="email"
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your email!",
                    },
                  ]}
                >
                  <Input
                    className="sign-innn"
                    autoComplete="off"
                    placeholder="Enter email"
                  />
                </Form.Item>
                <Form.Item
                  className="Contact-Number-fild"
                  label="Contact Number"
                  name="contactNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Contact Number!",
                    },
                  ]}
                >
                  <PhoneInput
                    className="tel-input"
                    country={"us"}
                    name="contactNumber"
                    value={form.getFieldValue("contactNumber")}
                    onChange={(e) => form.setFieldValue("contactNumber", e)}
                  />
                </Form.Item>
                <Form.Item
                  className="message"
                  label="Message"
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your message!",
                    },
                  ]}
                >
                  <TextArea className="message" placeholder="Enter Message" />
                </Form.Item>
                <div className="awla-whats-app">
                  <Form.Item valuePropName="checked" name="whatsapp" noStyle>
                    <Switch checkedChildren="Yes" unCheckedChildren="No" />
                  </Form.Item>
                  <span className="ml-2">{lang("Available on WhatsApp")}</span>
                </div>
                <Form.Item className="sign-innbtn">
                  <Button className="sign-btn" type="primary" htmlType="submit">
                    {lang("Buy")}
                  </Button>
                </Form.Item>
                {/* <span
                  onClick={() => {
                    setRegister(true);
                    setOpen(false);
                  }}
                  className="forgate-btn"
                >
                  Don't have account ? <span> Sign up</span>
                </span> */}
              </Form>
            </div>
          </Col>
        </Row>
      </Modal>
      <RegisterModal open={register} setOpen={setRegister} />
    </div>
  );
};

export default BuyModal;
