import React from "react";
import { Layout, Button, Typography, Form, Row,Col } from "antd";

import { Container } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Home = () => {
  return (
    <>
      <Container className=" main-outer-s ">
         <div className="catergrory-header">
             <Skeleton count={1}  width={150} height={30} />
              <div>
              <Skeleton count={1}  width={150} height={30} />
              </div>
            </div>
      </Container>
      <Container className="d-flex gap-3 justify-content-between">
        <Skeleton count={1} circle width={150} height={150} />
        <Skeleton count={1} circle width={150} height={150} />
        <Skeleton count={1} circle width={150} height={150} />
        <Skeleton count={1} circle width={150} height={150} />
        <Skeleton count={1} circle width={150} height={150} />
        <Skeleton count={1} circle width={150} height={150} />
      </Container>
      <Container className="  mt-4 gap-3">
      <div className="catergrory-header">
             <Skeleton count={1}  width={150} height={30} />
              
            </div>
        <Row  gutter={[24, 24]}>
        <Col span={24} sm={12} md={8} lg={6} xxl={4}>
          <Skeleton count={1} width={200} height={300} />
        </Col>
        <Col span={24} sm={12} md={8} lg={6} xxl={4}>
          <Skeleton count={1} width={200} height={300} />
        </Col>
        <Col span={24} sm={12} md={8} lg={6} xxl={4}>
          <Skeleton count={1} width={200} height={300} />
        </Col>
        <Col span={24} sm={12} md={8} lg={6} xxl={4}>
          <Skeleton count={1} width={200} height={300} />
        </Col>
        <Col span={24} sm={12} md={8} lg={6} xxl={4}>
          <Skeleton count={1} width={200} height={300} />
        </Col>
        <Col span={24} sm={12} md={8} lg={6} xxl={4}>
          <Skeleton count={1} width={200} height={300} />
        </Col>
        <Col span={24} sm={12} md={8} lg={6} xxl={4}>
          <Skeleton count={1} width={200} height={300} />
        </Col>
        <Col span={24} sm={12} md={8} lg={6} xxl={4}>
          <Skeleton count={1} width={200} height={300} />
        </Col>
        <Col span={24} sm={12} md={8} lg={6} xxl={4}>
          <Skeleton count={1} width={200} height={300} />
        </Col>
        <Col span={24} sm={12} md={8} lg={6} xxl={4}>
          <Skeleton count={1} width={200} height={300} />
        </Col>
        <Col span={24} sm={12} md={8} lg={6} xxl={4}>
          <Skeleton count={1} width={200} height={300} />
        </Col>
        <Col span={24} sm={12} md={8} lg={6} xxl={4}>
          <Skeleton count={1} width={200} height={300} />
        </Col>
        <Col span={24} sm={12} md={8} lg={6} xxl={4}>
          <Skeleton count={1} width={200} height={300} />
        </Col>
        <Col span={24} sm={12} md={8} lg={6} xxl={4}>
          <Skeleton count={1} width={200} height={300} />
        </Col>
        <Col span={24} sm={12} md={8} lg={6} xxl={4}>
          <Skeleton count={1} width={200} height={300} />
        </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
