import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to top using both documentElement and scrollTo
    const scrollOptions = { top: 0, left: 0, behavior: "smooth" };

    if (document.documentElement.scrollTo) {
      document.documentElement.scrollTo(scrollOptions);
    } else {
      window.scrollTo(0, 0);
    }

    // Fallback for older browsers
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [pathname]);

  return null;
};

export default ScrollToTop;
