import { Layout, Button, Typography, Form, Row, Col } from "antd";
import React, { useContext, useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";

import emojiImg from "../../src/assets/images/popurlar-emoji.png";
import ArrowImg from "../../src/assets/images/arrow.png";
import OwlCarousel from "react-owl-carousel3";
import Iphone1Img from "../assets/images/img-not.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Arrow1Img from "../../src/assets/images/arroeright.png";
import { Container } from "react-bootstrap";

import NoImage from "../../src/assets/images/noimageicon.png";
import ErrowLeft from "../../src/assets/images/prev.svg";
import ErrowRight from "../../src/assets/images/next.svg";
import { Link } from "react-router-dom";
import useRequest from "../hooks/useRequest";
import { Severty, ShowToast } from "../helper/toast";
import withLoadingSkeleton from "../helper/Skeleton/withLoadingSkeleton";
import Footer from "../components/layout/Footer";
import WhatsAppIcon from "../assets/images/whatsapp-icon.svg";
import { attempt } from "lodash";
import BuyModal from "../components/layout/BuyModel";
import { AuthContext } from "../context/AuthContext";
import lang from "../helper/lang";

const responsiveSetting = {
  0: {
    items: 1,
  },
  300: {
    items: 3,
  },
  400: {
    items: 3,
  },
  600: {
    items: 3,
  },
  800: {
    items: 4,
  },
  1000: {
    items: 5,
  },
  1200: {
    items: 6,
  },
  1400: {
    items: 6,
  },
};
const responsiveSettings = {
  0: {
    items: 1,
  },
  300: {
    items: 2,
  },

  600: {
    items: 2,
  },
  800: {
    items: 3,
  },
  1000: {
    items: 3,
  },
  1200: {
    items: 4,
  },
  1400: {
    items: 4,
  },
};

const HomeIndex = ({ setLoading, setType, setPage, ...props }) => {
  setPage("home");
  const [categories, setCategories] = useState([]);
  const [buyModel, setBuyModel] = useState(false);
  const [productId, setProductId] = useState("");
  const [data, setData] = useState({});
  const { request } = useRequest();
  const toggleModel = (value) => {
    setBuyModel(value);
    setProductId("");
  };

  useEffect(() => {
    request({
      url: "/common/category-list",
      method: "GET",
      onSuccess: (data) => {
        setCategories(data.data.data);
        // setLoading(false);
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
        // setLoading(false);
      },
    });
    request({
      url: "/common/main",
      method: "GET",
      onSuccess: (data) => {
        setData(data.data);
        // setLoading(false);
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
      },
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (data.featuredProducts) {
      setLoading(false);
    }
  }, [data]);

  const handleError = (event) => {
    event.target.src = NoImage; // Fallback image if the original one fails
    event.target.style.width = "100px";
  };
  return (
    <div className="main-outer-s">
      <BuyModal id={productId} open={buyModel} setOpen={toggleModel} />

      <section className="categrory-1">
        <div className="container">
          <div className="categrary-main">
            <div className="catergrory-header">
              <h3>{lang("Categories")}</h3>
              <div>
                <Link to="/category/all">View All</Link>
              </div>
            </div>

            <div className="catergrory_type">
              <OwlCarousel
                className="owl-theme category-Id"
                loop={false}
                margin={30}
                nav={false}
                dots={false}
                responsive={responsiveSetting}
              >
                {categories.map((item) => {
                  return (
                    <Link to={`/category/${item._id}`}>
                      <div className="categrogy-imgg">
                        <div className="catry-1-immg">
                          <img
                            src={item.image || NoImage}
                            alt={item.name}
                            style={{ width: "100%", height: "100" }}
                            onError={handleError}
                          />
                        </div>
                        <h5 className="category-name-h5">{item.name}</h5>
                      </div>
                    </Link>
                  );
                })}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>

      {data.featuredProducts && (
        <section className="container">
          <div className="Popular-brand-main">
            <div className="catergrory-header">
              <h3 className="category-name-h5">{lang("Featured Products")}</h3>
            </div>
            <div className="Popular-brand-main-2">
              <Row gutter={[24, 24]}>
                {data.featuredProducts.map((item) => {
                  const productName = item.title;
                  const price = new Intl.NumberFormat("de-DE").format(
                    item.price
                  );
                  const lowestPrice = new Intl.NumberFormat("de-DE").format(
                    item.lowestPrice
                  );
                  const description = item.description;
                  const imageUrl = item.main_image;
                  const message = `Check out this amazing product! ${productName}\nPrice: $${price}\nDescription: ${description}\n\nView Image: ${imageUrl}`;
                  const encodedMessage = encodeURIComponent(message);
                  const whatsappUrl = `https://wa.me/32460203984?text=${encodedMessage}`;
                  return (
                    <Col span={24} sm={12} md={8} lg={6} xxl={4}>
                      <div className="birthday-new catere">
                        <div className="wedding-main">
                          <div className="poporlasr-red-div">
                            <h4>
                              {lang("Featured Products")}{" "}
                              <img src={Arrow1Img} />
                            </h4>
                          </div>
                        </div>
                        <Link to={`/product-detail/${item.variant_id}`}>
                          <div className="phone-immgand-txxt">
                            <div className="phone-immg">
                              <img
                                src={
                                  item.main_image ? item.main_image : Iphone1Img
                                }
                                alt=""
                              />
                            </div>

                            <div className="phone-txxt">
                              <h5>{item.title}</h5>
                              <h6>
                                <span>From</span>$
                                {lowestPrice ? lowestPrice : price}
                              </h6>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </section>
      )}
      <Footer />
    </div>
  );
};

export default withLoadingSkeleton(HomeIndex);
