import { Col, Button } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import Iphone1Img from "../assets/images/img-not.png";
import "bootstrap/dist/css/bootstrap.min.css";
import ReportImg from "../assets/images/massagechat.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Severty, ShowToast } from "../helper/toast";
import useRequest from "../hooks/useRequest";
import WhatsAppIcon from "../assets/images/whatsapp-icon.svg";
import { AuthContext } from "../context/AuthContext";
import withLoadingSkeleton from "../helper/Skeleton/withLoadingSkeleton";
import Footer from "../components/layout/Footer";
import BuyModal from "../components/layout/BuyModel";
import lang from "../helper/lang";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const ProductDetailIndex = ({ setLoading, setPage, setType }) => {
  setPage("product");

  const { id } = useParams();
  const { request } = useRequest();
  const [openLiteBox, setOpenLiteBox] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [productDetails, setProductDetails] = useState({});
  const [allAttributes, setAllAttributes] = useState({});
  const [isFavorite, setIsFavorite] = useState(false);
  const [isCartAdded, setIsCartAdded] = useState(false);
  const [buyModel, setBuyModel] = useState(false);
  const [isClear, setIsClear] = useState(false);
  const [filters, setFilters] = useState([]);
  const [mainImage, setMainImage] = useState("");
  const [prId, setPrId] = useState(id);
  const [whatsApp, setWhatsApp] = useState({});
  const [initStage, setInitStage] = useState(true);
  const [currentActiveFilter, setCurrentActiveFilter] = useState({});
  const { setFavoriteCount, setCartCount, userProfile } =
    useContext(AuthContext);

  useEffect(() => {
    if (id) {
      setPrId(id);
    }
  }, [id]);
  useEffect(() => {
    if (prId && initStage) {
      setType("skeleton");
      setLoading(true);
      request({
        url: `/common/product/${prId}`,
        method: "POST",
        data: {},
        onSuccess: (data) => {
          setProductDetails(data.data.result);
          setPhotoIndex(() => 0);
          setImages([data.data.result.main_image, ...data.data.result.image]);
          setIsCartAdded(data.data.isInCart);
          setPrId(data.data.result._id);
          setIsFavorite(data.data.isInFavorite);
          setAllAttributes(data.data.allAttribute);
          setLoading(false);
        },
        onError: (error) => {
          console.log(error);
          ShowToast(error, Severty.ERROR);
          setLoading(false);
        },
      });
    }
  }, [prId, initStage]);
  useEffect(() => {
    const productName = productDetails.title;
    const price = new Intl.NumberFormat("de-DE").format(productDetails.price);
    const description = productDetails.description;
    const imageUrl = productDetails.main_image;
    const message = `Check out this amazing product! ${productName}\nPrice: $${price}\nDescription: ${description}\n\nView Image: ${imageUrl}`;
    const encodedMessage = encodeURIComponent(
      productDetails?.whatsappContent || message
    );
    const whatsappUrl = `https://wa.me/17867367269?text=${encodedMessage}`;
    setWhatsApp(whatsappUrl);
  }, [productDetails]);

  useEffect(() => {
    if (filters.length > 0) {
      let query = filters.reduce((acc, curr) => {
        acc = acc + `&${curr.name}=${curr.value}`;
        return acc;
      }, "?");

      setLoading(true);
      request({
        url: `/common/product/${prId}${query}`,
        method: "POST",
        data: currentActiveFilter,
        onSuccess: (data) => {
          setPhotoIndex(() => 0);
          setProductDetails(data.data.result);
          setMainImage(data.data.result.main_image);
          setPrId(data.data.result._id);
          setImages([data.data.result.main_image, ...data.data.result.image]);
          setIsCartAdded(data.data.isInCart);
          setIsFavorite(data.data.isInFavorite);
          setAllAttributes(data.data.allAttribute);
          setIsClear(data.data.clearAttributes);
          setInitStage(false);
          setLoading(false);
        },
        onError: (error) => {
          console.log(error);
          ShowToast(error, Severty.ERROR);
          setLoading(false);
        },
      });
    }
  }, [filters]);
  const handleToCheckout = () => {
    if (userProfile && userProfile.loginType === "Guest") {
      setBuyModel(true);
    } else {
      let payload = {
        productId: prId,
      };
      setLoading(true);
      console.log("payload", payload);
      request({
        url: "/app/user/buy-product",
        method: "POST",
        data: payload,
        onSuccess: (data) => {
          ShowToast(data.message, Severty.SUCCESS);
          setLoading(false);
        },
        onError: (error) => {
          console.log(error);
          setLoading(false);
        },
      });
    }
  };
  const handleAttribute = (name, value) => {
    if (isClear) {
      setFilters([{ name, value }]);
    } else {
      setFilters((prev) =>
        prev.find((el) => el.name === name)
          ? prev.map((el) => (el.name === name ? { name, value } : el))
          : [...prev, { name, value }]
      );
    }
    setCurrentActiveFilter({ name, value });
    setAllAttributes((prev) => ({
      ...prev,
      [name]: { ...prev[name], selected: value },
    }));
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    const scrollContainerRef = useRef(null);
    const [showScrollUpIcon, setShowScrollUpIcon] = useState(false);
    const [showScrollDownIcon, setShowScrollDownIcon] = useState(false);

    useEffect(() => {
      const checkOverflow = () => {
        const element = scrollContainerRef.current;
        if (element) {
          setShowScrollDownIcon(element.scrollHeight > element.clientHeight);
          setShowScrollUpIcon(element.scrollTop > 0);
        }
      };

      checkOverflow();
      window.addEventListener("resize", checkOverflow);
      return () => window.removeEventListener("resize", checkOverflow);
    }, []);
    const handleScroll = () => {
      const element = scrollContainerRef.current;
      if (element) {
        setShowScrollUpIcon(element.scrollTop > 0);
        setShowScrollDownIcon(
          element.scrollTop + element.clientHeight < element.scrollHeight
        );
      }
    };

    const scrollUp = () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollBy({
          top: -100,
          behavior: "smooth",
        });
      }
    };

    const scrollDown = () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollBy({
          top: 100,
          behavior: "smooth",
        });
      }
    };
    return (
      <div
        className="carousel-button-group w-20"
        style={{
          height: "100%",
          top: 0,
          left: 0,
          position: "absolute",
        }}
      >
        <div
          className="carousel-button-group-container"
          ref={scrollContainerRef}
          onScroll={handleScroll}
        >
          {showScrollUpIcon && (
            <div className="custom-scroll-up" onClick={scrollUp}>
              <i class="fa-solid fa-angle-up"></i>
            </div>
          )}
          {rest.item.map((val, index) => {
            return (
              <span
                className={currentSlide === index ? "active" : ""}
                onClick={() => goToSlide(index)}
              >
                <img src={val} alt="products" />
              </span>
            );
          })}
          {showScrollDownIcon && (
            <div className="custom-scroll-down" onClick={scrollDown}>
              <i class="fa-solid fa-angle-down"></i>
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <div className="main-outer-s">
      <section className="for-bg-grey">
        <Container>
          <div className="row">
            <Col span={24} lg={12}>
              <div className="">
                {images && (
                  <div className="">
                    <Carousel
                      arrows={true}
                      responsive={responsive}
                      customButtonGroup={<ButtonGroup item={images} />}
                      itemClass="d-flex justify-content-end"
                      className="product-image"
                    >
                      {images.length > 0 &&
                        images.map((val, index) => (
                          <div
                            className="w-80"
                            key={val}
                            onClick={() => {
                              setOpenLiteBox(!openLiteBox);
                              setPhotoIndex(index);
                            }}
                          >
                            {val ? (
                              <img
                                src={val}
                                id="myimage"
                                className="d-block w-100"
                                alt="products"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = Iphone1Img;
                                }}
                              />
                            ) : (
                              <img
                                src={Iphone1Img}
                                id="myimage"
                                className="d-block w-100"
                                alt="products"
                              />
                            )}
                          </div>
                        ))}
                    </Carousel>
                  </div>
                )}
              </div>
              <div className="about-itemms d-none d-lg-block">
                <h3>{lang("About this item")}</h3>
                <p>{productDetails?.description}</p>
              </div>
            </Col>
            <Col span={24} lg={12}>
              <div className="product-qty-1">
                <div className="phone-name-qulity">
                  <div className="h3">{productDetails.title}</div>

                  <h1>
                    $
                    {new Intl.NumberFormat("de-DE").format(
                      productDetails.price
                    )}
                    <span className="text-decoration-none">
                      {productDetails.stock <= 0 && "Out of Stock"}
                    </span>
                  </h1>
                </div>
                <div className="ability_1">
                  {Object.keys(allAttributes).length > 0 &&
                    Object.keys(allAttributes).map((item) => {
                      const { values, selected } = allAttributes[item];
                      return (
                        <div className="ability_2" key={item}>
                          <h2>{item}</h2>
                          <div className="d-flex gap-2 flex-wrap">
                            {values &&
                              selected &&
                              values.map((value) => {
                                const { val, isActive } = value;
                                return (
                                  <div
                                    className={`new ${val === selected ? "bg-primary-btn" : ""
                                      } ${isActive ? "" : "bg-danger-btn"}`}
                                    onClick={() => handleAttribute(item, val)}
                                  >
                                    {val}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="wft-icon mt-3">
                <a
                  href={whatsApp}
                  target="_blank"
                  className="share-btn"
                  rel="noreferrer"
                >
                  <img alt="" src={WhatsAppIcon} />
                  <span>{lang("whatsapp")}</span>
                </a>
              </div>

              <div className="product-details-1">
                <div className="product-details-3">
                  <h5>{lang("Details of the Product")}</h5>
                  <ul>
                    {productDetails.attribute &&
                      productDetails.attribute.slice(0, 4).map((item) => (
                        <li>
                          <h3>{item.name}</h3>
                          <p>{item.value}</p>
                        </li>
                      ))}
                  </ul>
                </div>

                <div className="about-itemms d-block d-lg-none">
                  <h3>{lang("About this item")}</h3>
                  <p>{productDetails?.description}</p>
                  <div className="reportbutton-main">
                    <Button>
                      <img src={ReportImg} />{" "}
                      <span>{lang("Report an issue with this product")}</span>
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </Container>
      </section>
      <Footer />
      <BuyModal id={prId} open={buyModel} setOpen={setBuyModel} />
      <div className="add-to-cart-bttn">
        <Container>
          <div className="add-to-cart-1">
            <div className="wft-icon mt-3">
              <a
                href={whatsApp}
                target="_blank"
                className="share-btn"
                rel="noreferrer"
              >
                <img alt="" src={WhatsAppIcon} />
                <span>{lang("whatsapp")}</span>
              </a>
            </div>
            {/* <Button
              disabled={productDetails.stock <= 0}
              type="primary"
              className="button-main-1"
              onClick={() =>
                productDetails.stock > 0 && handleToCheckout("/cart")
              }
            >
              {productDetails.stock > 0
                ? lang("Buy Now")
                : lang("Out of Stock")}
            </Button> */}
          </div>
        </Container>
      </div>
      {openLiteBox && (
        <div className="lightbox">
          <Lightbox
            wrapperClassName="lightbox-wrapper"
            mainSrc={images[photoIndex]}
            reactModalProps={{ Button: true }}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setOpenLiteBox(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        </div>
      )}
    </div>
  );
};

export default withLoadingSkeleton(ProductDetailIndex);
