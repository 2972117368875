import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import "antd/dist/antd.css";
import "antd/dist/antd.min.css";


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);
 

