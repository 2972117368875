import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Input,
  Space,
  Table,
  Radio,
} from "antd";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import {
  DeleteOutlined,
  StarOutlined,
  HeartOutlined,
  StarFilled,
} from "@ant-design/icons";
import Header from "../components/layout/Header";
import OwlCarousel from "react-owl-carousel3";
import AndroiddImg from "../assets/images/android_download (1).png";
import IosdImg from "../assets/images/ios_download (1).png";
import ScannerImg from "../assets/images/Rectangle 4252 (1).png";
import background3Img from "../assets/images/background_casa-terms.png";
import FacebookImg from "../../src/assets/images/facebook.png";
import InstaImg from "../../src/assets/images/instagram.png";
import PinetImg from "../../src/assets/images/pinterest.png";
import MailImg from "../../src/assets/images/mail.png";
import PhonellImg from "../../src/assets/images/phone.png";
import ChatImg from "../../src/assets/images/chat.png";
import Footer from "../components/layout/Footer";
import useRequest from "../hooks/useRequest";
import { Severty, ShowToast } from "../helper/toast";
import withLoadingSkeleton from "../helper/Skeleton/withLoadingSkeleton";

const AboutusIndex = ({ setLoading, setType }) => {
  setType("loader");

  const [data, setData] = useState("");
  const { request } = useRequest();

  useEffect(() => {
    setLoading(true);
    request({
      url: "/common/static/about-us",
      method: "Get",
      onSuccess: (data) => {
        //ShowToast(data.message, Severty.SUCCESS);
        setData(data?.data?.pageContain?.description || "");
        setLoading(false);
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
        setLoading(false);
      },
    });
  }, []);
  return (
    <div className="main-outer-s">
      <section className="for-bg-white">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="terms"
                dangerouslySetInnerHTML={{ __html: data }}
              ></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default withLoadingSkeleton(AboutusIndex);
