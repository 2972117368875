import { Layout, Row, Col } from "antd";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import FacebookImg from "../../assets/images/facebook.png";
import InstaImg from "../../assets/images/instagram.png";
import TiktokIcon from "../../assets/images/tiktok.svg";
import MailImg from "../../assets/images/mail.png";
import Logo from "../../assets/images/CASA PHONE 1.png";
import PhonellImg from "../../assets/images/phone.png";
import { Link } from "react-router-dom";
import lang from "../../helper/lang";

const Footer = () => {
  return (
    <section className="for-black-bg">
      <Container>
        <div className="footer">
          <div className="footer-section">
            <h3>About Us</h3>
            <ul className="social-icons">
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/warranty">Warranty</Link>
              </li>
              <li>
                <Link to="/refund-policy">Refund Policy</Link>
              </li>
              <li>
                <Link to="/shipping-policy">Shipping Policy</Link>
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Support</h3>
            <ul>
              <li>
                <Link to="/faq">FAQs</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              {/* <li className="social-phone-i">
                {" "}
                <img src={PhonellImg} />{" "}
                <a
                  href="https://wa.me/17867367269"
                  target="_blank"
                  rel="noreferrer"
                >
                  +1 7867367269
                </a>
              </li> */}
            </ul>
          </div>
          <div className="footer-section">
            <h3>Legal Information</h3>
            <ul className="social-icons">
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-conditions">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/security-policy">Security Policy</Link>
              </li>
              <li>
                <Link to="/accessiblity-policy">Accessibility Statement</Link>
              </li>
              <li>
                <Link to="/compliance-statement">Compliance Statement</Link>
              </li>
            </ul>
          </div>
          {/* <div className="footer-section">
            <h3>SÍGUENOS</h3>
            <ul>
              <li>
                <Link to="/" className="ft-logo">
                  <img src={Logo} />
                </Link>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/share/1N5ynoeF8waCHxGh/?mibextid=LQQJ4d"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={FacebookImg} />{" "}
                </a>
                <a
                  href="https://www.instagram.com/casaphonecolombia?igsh=M29xZzNscWZ0bXZn"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={InstaImg} />{" "}
                </a>
                <a
                  href="https://www.tiktok.com/@casadeapplecolombia"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={TiktokIcon} />{" "}
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </Container>
      <div className="footer-bottom">
        <p>© 2024 {lang("ROK Electronics LLC. All Right Reserved.")}</p>
      </div>
    </section>
  );
};

export default Footer;
