import React, { createContext, useState, useEffect } from "react";
import { ShowToast, Severty } from "../helper/toast";
export const AppStateContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [userDetail, setUserDetail] = useState({});

  // useEffect(() => {
  //   if (userDetail) {
  //     localStorage.setItem("userProfile", JSON.stringify(userDetail));
  //   }
  // }, [userDetail]);

  return (
    <AppStateContext.Provider
      value={{
        userDetail,
        setUserDetail,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};
