import { Button } from "antd";

import React, { useContext, useEffect, useState } from "react";

import { Tabs } from "antd";

import { Link, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import PhoneImg from "../../src/assets/images/img-not.png";
import FacebookImg from "../../src/assets/images/facebook.png";
import InstaImg from "../../src/assets/images/instagram.png";
import PinetImg from "../../src/assets/images/pinterest.png";
import MailImg from "../../src/assets/images/mail.png";
import PhonellImg from "../../src/assets/images/phone.png";
import ChatImg from "../../src/assets/images/chat.png";
import useRequest from "../hooks/useRequest";
import { Severty, ShowToast } from "../helper/toast";
import withLoadingSkeleton from "../helper/Skeleton/withLoadingSkeleton";
import { AuthContext } from "../context/AuthContext";
import Footer from "../components/layout/Footer";
import lang from "../helper/lang";

const OrderDetails = ({ setLoading, setPage, setType }) => {
  setPage("cart");
  const [orderData, setOrderData] = useState([]);
  const navigate = useNavigate();
  const { request } = useRequest();

  useEffect(() => {
    const userProfile = localStorage.getItem("userProfile")
      ? JSON.parse(localStorage.getItem("userProfile"))
      : {};
    if (userProfile.loginType === "Guest" || !userProfile) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    fetchCart();
  }, []);

  const fetchCart = () => {
    setLoading(true);
    request({
      url: "/app/user/order",
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        console.log(data.data[0].orders);
        setOrderData(data.data[0].orders || []);
        setLoading(false);
      },
      onError: (error) => {
        console.log(error);
        ShowToast(error, Severty.ERROR);
        setLoading(false);
      },
    });
  };
  return (
    <div div className="main-outer-s">
      <section className="order-deyails-mains ">
        <Container>
          <h1>{lang("Items Ordered")}</h1>
          <div className="main-order-d-page">
            <table>
              <thead>
                <tr>
                  <th>{lang("ITEMS IMAGE")}</th>
                  <th>{lang("ITEM NAME")}</th>
                  <th>{lang("QUANTITY")}</th>
                  <th>{lang("PRODUCT PRICE")}</th>
                  <th>{lang("TOTAL PRICE")}</th>
                  <th>{lang("ORDER STATUS")}</th>
                  <th>{lang("ACTION")}</th>
                </tr>
              </thead>
              <tbody>
                {orderData.map((order) => {
                  const { orderItems } = order;
                  return (
                    orderItems &&
                    orderItems.map((item) => (
                      <tr>
                        <td>
                          <img
                            src={item.productDetails.main_image || PhoneImg}
                            width={80}
                          />
                        </td>
                        <td className="product-nameand-s">{item.productDetails.title}</td>
                        <td>{item.quantity}</td>
                        <td>${item.productDetails.price}</td>
                        <td>${item.totalAmount}</td>
                        <td>{order.request}</td>
                        <td className="cancel-o-btn">
                          <Button>{lang("CANCEL")}</Button>
                        </td>
                      </tr>
                    ))
                  );
                })}
              </tbody>
            </table>
          </div>
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export default withLoadingSkeleton(OrderDetails);
