import React, { useState } from "react";
import Home from "./Page/Home";
import Header from "./Page/Header";
import Category from "./Page/Category";
import Product from "./Page/Product";
import Cart from "./Page/Cart";
import { ThreeDots } from "react-loader-spinner";

const withLoadingSkeleton = (WrappedComponent) => {
  return (props) => {
    const [page, setPage] = useState("home");
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState("skeleton");
    return (
      <div>
        {loading && (
          <div className="">
            {type === "skeleton" && (
              <>
                {page === "home" && <Home />}
                {page === "Header" && <Header />}
                {page === "category" && <Category />}
                {page === "product" && <Product />}
                {page === "cart" && <Cart />}
                {page === "wishlist" && <Cart />}
                {/* Add more skeleton components for other pages */}
              </>
            )}
            {type === "loader" && (
              <div className="loader-spinner">
                <ThreeDots
                  visible={true}
                  height="150"
                  width="150"
                  color="#f83706"
                  radius="12"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            )}
          </div>
        )}
        <div className={`${loading && type === "skeleton" ? "d-none" : ""}`}>
          <WrappedComponent
            setLoading={setLoading}
            setPage={setPage}
            setType={setType}
            {...props}
          />
        </div>
      </div>
    );
  };
};

export default withLoadingSkeleton;
