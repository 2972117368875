import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

import { AuthProvider, AuthContext } from "./context/AuthContext";
import { AppContextProvider } from "./context/AppContext";
import React, { useContext, Suspense } from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/style/main.css";

import Home from "./pages/Home";
import Loader from "./components/Loader";
import IndexHome from "./pages/IndexHome";
import Categories from "./pages/Categories";
import Terms from "./pages/Termspage";
import ProductDetail from "./pages/ProductDetail";
import Policy from "./pages/Policypage";
import Aboutus from "./pages/Aboutuspage";
import Header from "./components/layout/Header";
import Wishlist from "./pages/Wishlistitem";
import Cart from "./pages/Cart";
import OrderDetails from "./pages/OrderDetails";
import ProfileDetails from "./pages/ProfileDetails";
import ProfileEdit from "./pages/EditProfile";
import ScrollToTop from "./helper/ScrollToTop";
import SearchResult from "./pages/SearchResult";
import Aboutuspage from "./pages/Aboutuspage";
import ContactUs from "./pages/ContactUs";
import ComplianceStatement from "./pages/ComplianceStatement";
import AccessiblityPolicy from "./pages/AccessiblityPolicy";
import SecurityPolicy from "./pages/SecurityPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import ShippingPolicy from "./pages/ShippingPolicy";
import Warranty from "./pages/Warranty";
import Faq from "./pages/Faq";

window.Buffer = window.Buffer || require("buffer").Buffer;
function App() {
  return (
    <AuthProvider>
      <AppContextProvider>
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <ToastContainer closeOnClick={false} />
            <AppRoutes />
          </BrowserRouter>
        </Suspense>
      </AppContextProvider>
    </AuthProvider>
  );
}

const AppRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <div>
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="/Home" element={<IndexHome />} />
          <Route path="/category/:id" element={<Categories />} />
          <Route path="/terms-conditions" element={<Terms />} />
          <Route path="/product-detail/:id" element={<ProductDetail />} />
          <Route path="/privacy-policy" element={<Policy />} />
          <Route path="/about-us" element={<Aboutuspage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/compliance-statement" element={<ComplianceStatement />} />
          <Route path="/accessiblity-policy" element={<AccessiblityPolicy />} />
          <Route path="/security-policy" element={<SecurityPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/shipping-policy" element={<ShippingPolicy />} />
          <Route path="/warranty" element={<Warranty />} />
          <Route path="/faq" element={<Faq />} />

          {/* <Route path="/about-us" element={<Aboutus />} /> */}
          <Route path="/cart" element={<Cart />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/search-result" element={<SearchResult />} />
        </Routes>
      </div>
    </>
  );
};

const Layout = () => {
  return (
    <>
      {" "}
      <Outlet />{" "}
    </>
  );
};

export default App;
