import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Header = () => {
  return (
    
    <div className="row mb-1" style={ {height:"138px"}}>
       <div className="col-12">
        <Skeleton count={1} width={"100%"} height={50} />
      </div>
      <div className="col-1"></div>
      <div className="col-2">
        <Skeleton count={1} width={"100%"} height={50} />
      </div>
      <div className="col-4 d-flex align-items-center">
        <Skeleton count={1} width={500} height={35} />
      </div>
      <div className="col-3"></div>
      <div className="col-2 d-flex align-items-center gap-3">
        <Skeleton count={1} circle width={40} height={40} />
        <Skeleton count={1} circle width={40} height={40} />
        <Skeleton count={1} circle width={40} height={40} />
        <Skeleton count={1} circle width={40} height={40} />
      </div>
    </div>
    
  );
};

export default Header;
